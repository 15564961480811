





















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'
import Pagination from '@/components/Pagination/index.vue'
import {CustomerOrder, GiftCard, Program} from '@/models'

@Component({
  name: 'CustomerOrdersTable',
  components: {
    Pagination,
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private customerOrdersList: CustomerOrder[] = []
  private giftCards: any[] = []
  private listLoading = true
  private giftCardsLoading = false

  private programs: any[] = []
  private programsLoading = false

  private total = 0
  private tableKey = 0
  private listQuery = {
    page: 1,
    limit: 20,
    number: undefined,
    email: undefined,
    deliveryStatus: undefined,
    giftCardId: '',
    programId: undefined,
    dateRange: null,
    paymentStatus: undefined
  }

  private deliveryStatusOptions = [
    {
      value: ['undelivered', 'pull_error'],
      label: 'Non livrée'
    },
    {
      value: 'in_delivery',
      label: 'En livraison'
    },
    {
      value: 'delivered',
      label: 'Livrée'
    },
    {
      value: 'pull_error',
      label: 'Erreur'
    }
  ]

  private paymentStatusOptions = [
    {
      value: 'paid',
      label: 'Payée'
    },
    {
      value: 'unpaid',
      label: 'Non payée'
    },
    {
      value: 'failure',
      label: 'Echec'
    },
    {
      value: 'refunded',
      label: 'Remboursée'
    }
  ]

  created() {
    this.getCustomerOrdersList()
    this.getGiftCards()
    this.getPrograms()
  }

  private async deliverAll() {
    return await CustomerOrder.deliverAll()
  }

  private async getCustomerOrdersList() {
    this.listLoading = true
    const createdAtGte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange![1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } = await CustomerOrder
      .includes(['customer', 'cardDistributions', 'giftCard', 'customerInvoice'])
      .where({
        giftCardId: this.listQuery.giftCardId,
        number: { prefix: this.listQuery.number },
        deliveryStatus: this.listQuery.deliveryStatus,
        email: { prefix: this.listQuery.email },
        programId: this.listQuery.programId,
        createdAt: { gte: createdAtGte, lte: createdAtLte },
        paymentStatus: this.listQuery.paymentStatus
      })
      .page(this.listQuery.page)
      .per(this.listQuery.limit)
      .order({ createdAt: 'desc' })
      .stats({ total: 'count' })
      .all()

    this.customerOrdersList = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getGiftCards() {
    this.giftCardsLoading = true
    const { data } = await GiftCard.per(500).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.giftCards = Object.values(tempList)
    this.giftCardsLoading = false
  }

  private async getPrograms() {
    this.programsLoading = true
    const { data } = await Program
        .page(1)
        .per(999)
        .order('name')
        .all()

    this.programs = data
    this.programsLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.listQuery.giftCardId = giftCardId
    this.handleFilter()
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getCustomerOrdersList()
  }

  private async handleDelete(data: CustomerOrder, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Customer order deleted',
        type: 'success',
        duration: 2000
      })
      this.customerOrdersList.splice(index, 1)
      this.total -= 1
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<CustomerOrder> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private handleAction(command: any, customerOrder: CustomerOrder) {
    if (command === 'edit') this.$router.push({ name: 'CustomerOrdersEdit', params: { id: customerOrder.id } })
    else if (command === 'invoice') window.open(customerOrder.customerInvoice.file)
  }
}
